/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { PPWebModels } from '@/store/ppWebModels/types';
import { getLocalStorageService } from '@/services/storage.service'
import { Product } from '@/components/pages/shop/model-shop/model-shop.interface'

const namespace: string = 'ppWebModels';

export default class PpWebModelsViewingPeepPage extends Vue {
	@State('ppWebModels') ppWebModels: PPWebModels | undefined;
	@Action('storeModelTodo', {namespace}) storeModelTodo: any;
	@Action('storeServicesTodo', {namespace}) storeServicesTodo: any;
	@Action('fetchChatMessage', {namespace}) fetchChatMessage: any;
	@Action('storeChatMessage', {namespace}) storeChatMessage: any;
	@Action('storeModelViewingPeep', {namespace}) storeModelViewingPeep: any;
	@Action('storeModelViewingPerMinOrAll', {namespace}) storeModelViewingPerMinOrAll: any;
	@Mutation('setCurrentProduct', {namespace}) setCurrentProduct: any;
	@Mutation('setIsSwitchChat', {namespace}) setIsSwitchChat: any;
	@Getter('getCardModelInfo', {namespace}) getCardModelInfo: any;
	@Getter('getMessageFromChat', {namespace}) getMessageFromChat: any;
	@Getter('getServicesCard', {namespace}) getServicesCard: any;
	@Getter('getIsSwitchChat', {namespace}) getIsSwitchChat: any;
	@Getter('getModelInfo', {namespace}) getModelInfo: any;

	modelChat: any = {
		chatMessage: {
			message: ''
		}
	}
	currentProduct: Product = {
		id: 0,
		img: '',
		name: '',
		price: 0
	}
	onModelTodoButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeModelTodo({
			todoModel: target.innerText
		})
	}
	sendChatMessage() {
		const name = getLocalStorageService('login');
		this.storeChatMessage({
			username: name,
			message: this.modelChat.chatMessage.message
		});
		this.modelChat.chatMessage.message = '';
	}
	selectedProduct(name: string) {
		this.setCurrentProduct({
			id: this.currentProduct.id++,
			img: '/path/file.png',
			name: name,
			price: 300
		})
	}
	onServicesCard(name: string) {
		this.storeServicesTodo({
			services: name
		})
	}
	toSwitch() {
		this.setIsSwitchChat(!this.getIsSwitchChat);
	}
	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			this.storeModelViewingPeep({
				peep: true
			});
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			this.storeModelViewingPerMinOrAll({
				perMinutes: true
			})
			break;
		case 'allShow':
			this.storeModelViewingPerMinOrAll({
				allShow: true
			})
			break;
		default:
			break;
		}
	}
	mounted() {
		this.fetchChatMessage();
	}
}
